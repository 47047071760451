
import { defineComponent, ref } from 'vue'
import { getDealerInfo } from '@/API/rv/rvSicco'
import moment from 'moment'
export default defineComponent({
    setup() {
			const data = ref({})
      const params = {
        id: Number(sessionStorage.getItem('SiccoRecordId'))
      }
      getDealerInfo({params}).then(res => {
        data.value = res
      })
      return {
        moment,
				data
      }
    },
})
