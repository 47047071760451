import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_info = _resolveComponent("basic-info")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_dealers_info = _resolveComponent("dealers-info")!
  const _component_retail_history_info = _resolveComponent("retail-history-info")!
  const _component_oper_record = _resolveComponent("oper-record")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: "零售数据详细信息",
      class: "retailModal",
      width: "84%",
      center: "",
      style: {"height":"650px","padding-bottom":"0","top":"50px"},
      visible: _ctx.modal,
      footer: null,
      onCancel: _ctx.close
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tabs, {
          defaultActiveKey: "1",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeTab(_ctx.event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              tab: "基本信息",
              key: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_info)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              tab: "经销商信息",
              key: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_dealers_info)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              tab: "车辆零售历史信息",
              key: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_retail_history_info)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, {
              tab: "操作记录",
              key: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_oper_record)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "onCancel"])
  ]))
}