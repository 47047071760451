
import { defineComponent, computed, ref, watch, nextTick } from 'vue'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueDragResize from 'vue-drag-resize'

interface Position {
  x: number;
  y: number;
}

export default defineComponent({
  components: {
    VueDragResize
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: 520
    },
    height: {
      type: [String, Number]
    }
  },
  emits: [
    'update:Visible'
  ],
  setup(props, context) {
    const container = ref<HTMLElement | null>(null)
    const component = ref('div')
    const position = ref<Position>({x: 0, y: 0})


    const handleCloseModal = () => {
      context.emit('update:Visible', false)
    }

    const modalWidth = computed(() => {
      if (typeof props.width === 'number') {
        return `${props.width}px`
      } else {
        return props.width
      }
    })

    const modalHeight = computed(() => {
      if (typeof props.height === 'number') {
        return `${props.height}px`
      } else {
        return props.height
      }
    })

    const watchStopHandler = watch(() => props.visible, (visible) => {
      if (!visible) {
        return
      }
      if (component.value === 'div') {
        nextTick(() => {
          const el = container.value
          if (el) {
            position.value.x = el.offsetLeft
            position.value.y = el.offsetTop
          }
          component.value = 'vue-drag-resize'
        })
        watchStopHandler()
      }
    })

    return {
      container,
      component,
      position,
      handleCloseModal,
      modalWidth,
      modalHeight
    }
  }
})
