
import { defineComponent, ref, reactive, toRefs } from 'vue';
import useSiccoOverview from '@/hooks/rv/useSiccoOverview';
import useDealerOverview from '@/hooks/rv/useDealerOverview';
import SiccoDetail from '@/views/RetailValidation/Sicco/SiccoDetail/index.vue';
import { EnumApprovalStatus, EnumCheckStatus, SiccoTableData } from '@/views/RetailValidation/Sicco/types';
import { postBatchReject, postApproval } from '@/API/rv/rvSicco';
import { message } from 'ant-design-vue';
const tableHeight = window.innerHeight - 456;
const tableWidth = window.innerWidth;
const usageArray = ['最终用户用车', '经销商用车', '其他', '全部'];
const specialUsageArray = ref<any>([]);
const entityArray = ['FBAC', 'BBAC', 'MBCL', '全部'];
const brandArray = ['MB', 'AMG', 'EQ', 'VAN', 'DENZA', 'Maybach', '全部'];
import { getSpecialUsageItem } from '@/API/rv/rvDealer';
import MovableModal from '@/views/RetailValidation/component/MovableModal.vue';
import BrandSelector from '@/views/RetailValidation/component/BrandSelector.vue';

export default defineComponent({
  components: {
    SiccoDetail,
    MovableModal,
    BrandSelector
  },
  setup() {
    // 详情弹窗是否显示
    const isDetailVisible = ref<boolean>(false);
    const isOpenRejectModal = ref<boolean>(false);
    // 批量拒绝内容
    const rejectContent = ref('');
    // 批量拒绝提示
    const isRejectModal = ref(false);
    // 是否显示提示
    const isToastShow = ref(false);
    // 发票，行驶证 查看 复用Dealer
    const { pictureModal, invoiceEvent, licenseEvent, downloadImg, relationProofEvent } = useDealerOverview();
    const {
      queryParams,
      isOpenPanle,
      isOpen,
      total,
      activeKey,
      resetEvent,
      mulSearchEvent,
      overviewSerachEvent,
      handleActiveKey,
      batchQuery,
      pageChange,
      sizeChange,
      handleClick,
      columns,
      tableData,
      overview,
      approvalStatusArray,
      checkStatusArray,
      showCollapsed,
      closeBatchQueryModal,
      clearBatchQuery,
      textChangEvent,
      batchQueryEvent,
      textContentNo,
      textNoArray,
      batchPlaceholder,
      input,
      handleSearch,
      dealerNameEnArray,
      swtArray,
      coficoArray,
      dateChangeEvent,
      dealerNameCnArray,
      exportResult,
      init,
      selectData,
      handleBatchDownload,
      getTableData,
      handleGetSiccoOverview
    } = useSiccoOverview();
    //初始化概览和状态
    init();
    //查看详情
    const detailEvent = (record: SiccoTableData) => {
      sessionStorage.setItem('SiccoRecordId', String(record.id));
      sessionStorage.setItem('SiccoRecordEntity', record.entity);
      sessionStorage.setItem('SiccoRecordVinNo', record.vinNo);
      sessionStorage.setItem('SiccoRecordDealerCode', record.dealerCode);
      isDetailVisible.value = true;
    };
    // 关闭弹窗
    const closeDetail = (flag: boolean) => {
      isDetailVisible.value = false;
      flag && getTableData();
      flag && handleGetSiccoOverview();
    };
    // 表格复选框逻辑
    const state = reactive({
      selectedRowKeys: [], // Check here to configure the default column
      selectedRows: []
    });
    const onSelectChange = (selectedRowKeys: [], selectedRows: []) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };
    // 校验是否选择车辆信息
    const checkSelectedRows = () => {
      if (state.selectedRows.length < 1) {
        isToastShow.value = true;
        return false;
      } else {
        return true;
      }
    };
    const approvalEvent = () => {
      if (!checkSelectedRows()) {
        return;
      }
      const hasUncommited = state.selectedRows.some((item: SiccoTableData) => item.approlStatus !== '2222');
      if (hasUncommited) {
        message.error('请选择已提交的车辆');
        return;
      }
      const param: number[] = [];
      state.selectedRows.forEach((item: SiccoTableData) => {
        param.push(item.id);
      });
      postApproval({ ids: param }).then((res) => {
        if (res.code === '0') {
          message.success('操作成功');
          state.selectedRowKeys = [];
          state.selectedRows = [];
          isOpenRejectModal.value = false;
          mulSearchEvent();
          handleGetSiccoOverview();
        } else {
          message.error('操作失败');
        }
      });
    };
    const rejectModalEvent = () => {
      if (!checkSelectedRows()) {
        return;
      }
      const hasUncommited = state.selectedRows.some((item: SiccoTableData) => item.approlStatus !== '2222');
      if (hasUncommited) {
        message.error('请选择已提交的车辆');
        return;
      }
      isOpenRejectModal.value = true;
      rejectContent.value = '';
    };
    const saveEvent = () => {
      if (rejectContent.value === '') {
        message.error('请输入审核失败原因');
      } else {
        isRejectModal.value = true;
        isOpenRejectModal.value = false;
      }
    };
    // 拒绝弹窗确定事件
    const rejectEvent = () => {
      const param = { comments: rejectContent.value, vinInfos: [{}] };
      param.vinInfos.pop();
      state.selectedRows.forEach((item: SiccoTableData) => {
        param.vinInfos.push({
          vinNo: item.vinNo,
          dealerCode: item.dealerCode
        });
      });
      postBatchReject(param).then((res) => {
        if (res === '') {
          message.success('操作成功');
          state.selectedRowKeys = [];
          state.selectedRows = [];
          rejectContent.value = '';
          isRejectModal.value = false;
          mulSearchEvent();
          handleGetSiccoOverview();
        } else {
          message.error('操作失败');
        }
      });
    };
    //获取特殊车辆
    const getSpecialUsageArray = () => {
      getSpecialUsageItem().then((res) => {
        specialUsageArray.value = res.map((item: any) => item.specialUsageCn);
      });
    };
    getSpecialUsageArray();

    return {
      queryParams,
      overview,
      isOpen,
      isOpenPanle,
      showCollapsed,
      activeKey,
      exportResult,
      resetEvent,
      usageArray,
      mulSearchEvent,
      overviewSerachEvent,
      handleClick,
      detailEvent,
      columns,
      tableData,
      tableHeight,
      total,
      pageChange,
      sizeChange,
      specialUsageArray,
      entityArray,
      rejectContent,
      onSelectChange,
      ...toRefs(batchQuery),
      ...toRefs(state),
      ...toRefs(queryParams),
      ...toRefs(pictureModal),
      downloadImg,
      isDetailVisible,
      closeDetail,
      handleActiveKey,
      tableWidth,
      approvalStatusArray,
      checkStatusArray,
      EnumApprovalStatus,
      EnumCheckStatus,
      batchQueryEvent,
      closeBatchQueryModal,
      clearBatchQuery,
      textChangEvent,
      textContentNo,
      textNoArray,
      batchPlaceholder,
      licenseEvent,
      invoiceEvent,
      isOpenRejectModal,
      rejectEvent,
      input,
      rejectModalEvent,
      approvalEvent,
      dealerNameEnArray,
      handleSearch,
      saveEvent,
      isRejectModal,
      swtArray,
      coficoArray,
      dealerNameCnArray,
      isToastShow,
      dateChangeEvent,
      ...toRefs(selectData),
      brandArray,
      handleBatchDownload,
      relationProofEvent
    };
  }
});
