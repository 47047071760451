
import { defineComponent } from 'vue'
import BasicInfo from './BasicInfo.vue'
import RetailHistoryInfo from './RetailHistoryInfo.vue'
import OperRecord from './OperRecord.vue'
import DealersInfo from './DealersInfo.vue'
export default defineComponent({
  components: {
    BasicInfo,
    RetailHistoryInfo,
    OperRecord,
    DealersInfo
  },
  props: {
    isDetailVisible: {
        type: Boolean,
        required: true,
        default: false
    }
  },
  setup (props, context) {
    const modal = (props as any).isDetailVisible
    const close = (flag = true) => {
      context.emit('close-detail-visible', flag)
    }
    const changeTab = (event: string) => {
      const dom = document.getElementsByClassName('ant-table-body')[(~~event) - 2]
      const baseDom = document.getElementsByClassName('base-info')[0]
      if (dom) {
         dom.scrollTop = 0
      }
      if (baseDom) {
        baseDom.scrollTop = 0
      }
    }
    return {
      modal,
      close,
      changeTab
    }
  }
})
