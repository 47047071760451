
import { defineComponent, ref } from 'vue';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import { getBasicInfo, postApproval, postSingleApproval } from '@/API/rv/rvSicco';
import { EnumApprovalStatus, EnumCheckStatus } from '@/views/RetailValidation/Sicco/types';
import { message } from 'ant-design-vue';
import base64toBlob from '@/utils/rv/base64toBlob';
import ImagePreview from '@/views/RetailValidation/component/ImagePreview.vue';
const columns = [
  { title: '内容', dataIndex: 'comments', width: '70%' },
  { title: '反馈时间', dataIndex: 'replyAt' }
];
interface PdfData {
  preSignedUrl: string;
  name: string;
  base64: string;
  docName: string;
}
export default defineComponent({
  components: {
    CloseCircleOutlined,
    ImagePreview
  },
  setup() {
    const loading = ref(true);
    const saveLoading = ref(false);
    const data = ref({
      approlStatus: '',
      attachFiles: [{ preSignedUrl: '', name: '' }],
      checkStatus: '',
      customerName: '',
      id: 0,
      invoiceDate: '',
      licenseDate: '',
      model: '',
      price: '',
      sysAutoCheckFailReason: '',
      usage: '',
      vinNo: ''
    });
    const pdfShow = ref(false);
    const pdfObj = ref<PdfData>({
      base64: '',
      name: '',
      preSignedUrl: '',
      docName: ''
    });
    const rejectContent = ref('');
    const params = { id: sessionStorage.getItem('SiccoRecordId') };
    const loadData = () => {
      getBasicInfo({ params }).then((res) => {
        if (res.price) {
          res.price = res.price.replace(/,/g, '');
          res.price = parseFloat(res.price).toFixed(2);
          res.price = '￥' + res.price.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
        }
        res.attachFiles.forEach((item) => {
          item.base64 = URL.createObjectURL(base64toBlob(item.base64, 'application/pdf'));
        });
        data.value = res;
        loading.value = false;
      });
    };
    // 初始化数据
    loadData();
    const isOpenRejectModal = ref<boolean>(false);
    const handleOk = () => {
      isOpenRejectModal.value = false;
    };
    const saveEvent = () => {
      saveLoading.value = true;
      postSingleApproval({
        id: data.value.id,
        comments: rejectContent.value,
        checkStatus: data.value.checkStatus
      }).then((res) => {
        if (res.code === '0') {
          message.success('操作成功');
          isOpenRejectModal.value = false;
        } else if (res.code === '50068') {
          message.error(res.message);
          isOpenRejectModal.value = false;
        } else {
          message.error(res.message);
        }
        // 初始化数据
        loadData();
        saveLoading.value = false;
      });
    };
    const approvalEvent = () => {
      if (data.value.approlStatus !== '2222') {
        message.error('请选择已提交的车辆');
        return;
      }
      loading.value = true;
      postApproval({
        ids: [data.value.id],
        checkStatus: data.value.checkStatus
      }).then((res) => {
        loading.value = false;
        if (res.code === '0') {
          message.success('操作成功');
          isOpenRejectModal.value = false;
        } else if (res.code === '50068') {
          message.error(res.message);
        } else {
          message.error(res.message);
        }
        // 初始化数据
        loadData();
      });
    };
    const rejectEvent = () => {
      if (data.value.approlStatus !== '2222') {
        message.error('请选择已提交的车辆');
        return;
      }
      isOpenRejectModal.value = true;
      rejectContent.value = '';
    };
    const scrollBottomEvent = () => {
      const baseDom = document.getElementsByClassName('base-info')[0];
      baseDom.scrollTo(0, 650);
    };
    const downloadImg = (picName: string, resource: string) => {
      const a = document.createElement('a');
      a.href = resource;
      a.setAttribute('download', `${picName}.jpg`);
      a.click();
    };
    const downloadFile = () => {
      const downloadElement = document.createElement('a');
      downloadElement.href = pdfObj.value.preSignedUrl;
      downloadElement.download = pdfObj.value.name;
      downloadElement.click();
    };
    return {
      data,
      columns,
      isOpenRejectModal,
      EnumApprovalStatus,
      EnumCheckStatus,
      handleOk,
      rejectContent,
      downloadImg,
      loading,
      approvalEvent,
      rejectEvent,
      saveEvent,
      saveLoading,
      scrollBottomEvent,
      downloadFile,
      pdfShow,
      pdfObj
    };
  }
});
